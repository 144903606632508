import { useState } from "react"
import { Backdrop, Button, CircularProgress, Paper, Typography, useMediaQuery } from "@mui/material"
import Swal from "sweetalert2"

import { initCheckoutSession } from "../../../../service/StripeDataService"


export default function UpgradeSection({ userId }) {
    const isMobile = useMediaQuery('(max-width:920px)')
    const [showLoading, setShowLoading] = useState(false)

    const handleCheckout = async () => {
        setShowLoading(true)
        const sessionOK = await initCheckoutSession(userId)
        if (!sessionOK) {
            setShowLoading(false)
            Swal.fire({
                title: "Can't Proceed to Checkout",
                text: "Something went wrong. Please try again later.",
                icon: "error"
            })
        }
    }

    return (
        <>
            <Paper
                elevation={4}
                sx={{
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "space-evenly",
                    alignItems: 'start',
                    margin: isMobile ? '.6rem 0 2rem 0' : '.6rem 2rem 2rem 2rem',
                    padding: '1rem 2.2rem',
                    gap: 2,
                    backgroundColor: 'primary.main',
                    color: '#fefefe',
                    borderRadius: '16px',
                }}>
                <Typography
                    variant="h5"
                    component="h3"
                    sx={{
                        fontSize: '2rem',
                        fontWeight: 800,
                        fontFamily: "inherit",
                    }}
                >
                    Unleash Your Creativity
                </Typography>
                <section>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontWeight: 400,
                            fontFamily: "inherit",
                            fontSize: '.86rem'
                        }}
                    >
                        Elevate your Vibio experience with Starlight.
                        <br />
                        7-day trial, cancel anytime.
                    </Typography>
                    <ul style={{ fontSize: '1rem' }}>
                        <li>Your personal brand, just the way you like it</li>
                        <li>Select from various font styles</li>
                        <li>Change how your buttons appear</li>
                        <li>Upload videos up to 200 MB</li>
                    </ul>

                </section>
                <section style={{ alignSelf: 'center', flex: 1 }}>
                    <Button
                        variant="contained"
                        sx={{
                            width: '100%',
                            textTransform: 'none',
                            background: '#fcfcfc',
                            color: '#000',
                            padding: isMobile ? '.8rem 2.2rem' : '.8rem 4rem',
                            margin: 0,
                            fontFamily: 'inherit',
                            fontWeight: 700,
                            '&:hover': {
                                background: 'rgba(252, 252, 252, .76)'
                            }
                        }}
                        onClick={handleCheckout}
                    >
                        Try Starlight for free
                    </Button>
                    <center>
                        <pre style={{ fontSize: '90%' }}>$2.99/month after</pre>
                    </center>
                </section>
            </Paper>
            <Backdrop
                sx={(theme) => ({ color: '#ff1493', zIndex: theme.zIndex.drawer + 1 })}
                open={showLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}