import axios from 'axios'


const backendUrl = process.env.REACT_APP_BACKEND_URL
const DOMAIN = `${backendUrl}/api/themes`


export async function getThemeById(themeId) {
    return await axios.get(`${DOMAIN}/${themeId}`)
}

// BUTTON
export async function updateButtonStyle(themeId, buttonStyleCode) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const isUpdateOK = await axios({
        method: "post",
        url: `${DOMAIN}/${themeId}/btn/${buttonStyleCode}/style`,
        headers,
    })
        .then(response => {
            if (response.status === 204) {
                return true
            }
        })
        .catch(err => {
            console.error(`Error occurred while updating button style: ${err}`)
            return false
        })
    return isUpdateOK
}

export async function updateButtonColor(themeId, buttonColor) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const isUpdateOK = await axios({
        method: "post",
        url: `${DOMAIN}/${themeId}/btn/${buttonColor}/color`,
        headers,
    })
        .then(response => {
            if (response.status === 204) {
                return true
            }
        })
        .catch(err => {
            console.error(`Error occurred while updating button color: ${err}`)
            return false
        })
    return isUpdateOK
}

// SOCIAL ICONS
export async function updateSocialIconColor(themeId, iconColor) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const isUpdateOK = await axios({
        method: "post",
        url: `${DOMAIN}/${themeId}/icon/${iconColor}`,
        headers,
    })
        .then(response => {
            if (response.status === 204) {
                return true
            }
        })
        .catch(err => {
            console.error(`Error occurred while updating social icon color: ${err}`)
            return false
        })
    return isUpdateOK
}

// FONT
export async function updateFontStyle(themeId, fontStyleCode) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const isUpdateOK = await axios({
        method: "post",
        url: `${DOMAIN}/${themeId}/font/${fontStyleCode}`,
        headers,
    })
        .then(response => {
            if (response.status === 204) {
                return true
            }
        })
        .catch(err => {
            console.error(`Error occurred while updating font style color: ${err}`)
            return false
        })
    return isUpdateOK
}

// PAGE
export async function updatePageBgColor(themeId, bgColor) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const isUpdateOK = await axios({
        method: "post",
        url: `${DOMAIN}/${themeId}/bg/${bgColor}`,
        headers,
    })
        .then(response => {
            if (response.status === 204) {
                return true
            }
        })
        .catch(err => {
            console.error(`Error occurred while updating Background color: ${err}`)
            return false
        })
    return isUpdateOK
}
