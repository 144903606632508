import React, { useEffect, useContext } from "react"
import { Box, Skeleton } from "@mui/material"

import ButtonDesign from "./ButtonDesign"
import PageDesign from "./PageDesign"
import FontDesign from "./FontDesign"
import IconDesign from "./IconDesign"
import { VibioThemeContext } from "../../../context/VibioThemeProvider"
import { getThemeById } from "../../../service/ThemeDataService"
import UpgradeSection from "./upgrade/UpgradeSection"

export default function DesignTab({ user }) {
    const { theme, updateTheme } = useContext(VibioThemeContext)

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                if (user.tier !== "LIGHT" && user?.videoPages[0]?.themeId) {
                    const response = await getThemeById(user?.videoPages[0]?.themeId)
                    updateTheme(response.data)
                }
            } catch (error) {
                console.error("Error fetching theme:", error)
            }
        }

        fetchTheme()
    }, [user?.tier, user?.videoPages, updateTheme])

    if (user.tier === "LIGHT") {
        return (
            <UpgradeSection userId={user?.userId} />
        )
    }

    return (
        <>
            {theme === null || !theme ? (
                <Box sx={{ m: 0, display: "flex", flexDirection: "column", gap: "2rem" }}>
                    <Skeleton variant="rounded" height={90} />
                    <Skeleton variant="rounded" height={120} />
                    <Skeleton variant="rounded" height={100} />
                </Box>
            ) : (
                <>
                    <PageDesign themeId={theme?.themeId} bgColor={theme?.bgColor} />
                    <IconDesign themeId={theme?.themeId} iconColor={theme?.iconColor} />
                    <FontDesign themeId={theme?.themeId} fontStyle={theme?.fontStyle} />
                    <ButtonDesign
                        themeId={theme?.themeId}
                        btnColor={theme?.buttonColor}
                        btnStyleCode={theme?.buttonStyle}
                    />
                </>
            )}
        </>
    )
}
