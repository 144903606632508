import axios from 'axios'


const backendUrl = process.env.REACT_APP_BACKEND_URL
const DOMAIN = `${backendUrl}/api`

export async function initCheckoutSession(userId) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const requestOK = axios({
        method: "post",
        url: `${DOMAIN}/stripe/checkout/${userId}`,
        headers,
    })
        .then(response => {
            if (response.status === 200) {
                window.location.href = response.data
                return true
            }
        })
        .catch(err => {
            console.error(`Error occurred while sending Checkout session request: ${err}`)
            return false
        })

    return requestOK
}
