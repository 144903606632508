import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import { useAuth } from "../../context/SecurityContextProvider"
import DropdownButton from '../buttons/DropdownButton'


export default function StudioNav() {
    const { userData } = useAuth()
    const isMobile = useMediaQuery('(max-width:950px)')

    return (
        <AppBar position="static" sx={{
            backgroundColor: 'primary.main',
            color: 'primary.bg',
            p: 1.4,
        }}>
            <Container maxWidth="xl">
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'space-between' : 'space-between',
                    alignItems: 'center',
                    m: 0,
                    fontFamily: 'Catamaran',
                }}>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            fontSize: '2.2rem',
                            fontWeight: 800,
                            fontFamily: 'inherit',
                            gap: 1
                        }}
                    >
                        Vibio
                    </Typography>

                    {/* if not on mobile */}
                    {
                        !isMobile &&
                        <Box sx={{
                            color: 'text.regular',
                        }}>
                            <DropdownButton username={isMobile ? <MenuIcon /> : `vibio.co/${userData.username}`} />
                        </Box>
                    }

                    {/* if on mobile */}
                    {
                        isMobile &&
                        <DropdownButton username={isMobile ? <MenuIcon /> : userData?.username} />
                    }

                </Toolbar>
            </Container>
        </AppBar>
    )
}

