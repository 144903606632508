import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Box, Divider, Typography, CircularProgress, useMediaQuery, Button, Fab, Snackbar } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'

import StudioNav from '../../nav/StudioNav'
import TabSection from '../../tabs/TabSection'
import { useAuth } from '../../../context/SecurityContextProvider'
import { VideoPagePreview } from '../videopage/preview/VideopagePreview'
import { LinksProvider } from '../../../context/LinkProvider'
import { MediaProvider } from '../../../context/MediaProvider'


export default function StudioView() {
    const { userData, loading, isAuthenticated } = useAuth()
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:1050px)')
    const [state, setState] = useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'center',
    })


    useEffect(() => {
        if (userData !== undefined) {
            if (userData.didCompleteSetup === false) {
                navigate('/welcome')
            }
        }
    }, [])

    if (loading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <CircularProgress />
            </Box>
        )
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />
    }


    const videoPage = userData.videoPages && userData.videoPages[0]
    const videoSrc = videoPage ? videoPage.videoSrc : ""


    const handleOpenSnackbar = () => {
        setState({ ...state, isOpen: true })
        setTimeout(() => {
            setState({ ...state, isOpen: false })
        }, 2000)
    }


    return (
        <>
            <LinksProvider user={userData}>
                <MediaProvider user={userData}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <StudioNav />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            m: 0,
                            p: 0,
                            gap: 1,
                            height: '92vh',
                            fontFamily: 'Catamaran',
                            backgroundColor: 'primary.bg',
                        }}>
                            {/* L */}
                            <Box sx={{
                                m: 0,
                                p: 0,
                                flex: 3,
                                color: 'text.second',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: isMobile ? '' : 'center',
                            }}>
                                <Snackbar
                                    anchorOrigin={{ vertical: state.vertical, horizontal: state.horizontal }}
                                    open={state.isOpen}
                                    message={
                                        <span style={{ display: 'flex', alignItems: 'start', gap: '8px' }}>
                                            Copied! <LibraryAddCheckOutlinedIcon />
                                        </span>
                                    }
                                    key={`${state.vertical}-${state.horizontal}`}
                                    ContentProps={{
                                        sx: {
                                            backgroundColor: 'primary.bg',
                                            color: 'text.second',
                                            borderRadius: '8px',
                                            fontFamily: 'inherit',
                                            fontSize: '1rem',
                                            fontWeight: 500,
                                            boxShadow: '0px 3px 3px #000',
                                            m: '.4rem',
                                            p: '.6rem 1rem',
                                        }
                                    }}
                                />
                                <Box>
                                    <section style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            alignItems: 'center',
                                            justifyContent: isMobile ? 'center' : 'space-between',
                                            margin: isMobile ? '0 .2rem' : '.4rem .2rem',
                                        }}>
                                            {
                                                !isMobile &&
                                                <Typography
                                                    variant="h6"
                                                    component="h5"
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontFamily: 'inherit',
                                                        textAlign: 'left',
                                                        m: '.8rem',
                                                    }}
                                                >
                                                    Welcome back,
                                                    <span style={{ fontWeight: 500 }}>
                                                        {" " + userData?.username}
                                                    </span>
                                                    !
                                                </Typography>
                                            }
                                            <Button
                                                variant="filled"
                                                color="primary"
                                                sx={{
                                                    fontFamily: 'inherit',
                                                    fontWeight: 500,
                                                    fontSize: '1rem',
                                                    background: '#000',
                                                    color: 'text.regular',
                                                    m: isMobile ? '.4rem .2rem' : '1rem 1rem 1rem 1.6rem',
                                                    textTransform: 'none',
                                                    borderRadius: '20px',
                                                    '&:hover': {
                                                        background: '#202020',
                                                    }
                                                }}
                                                endIcon={<ContentCopyRoundedIcon />}
                                                onClick={async () => {
                                                    await navigator.clipboard.writeText(`https://vibio.co/${userData?.username}`)
                                                    handleOpenSnackbar()
                                                }}
                                            >
                                                {`vibio.co/${userData?.username}`}
                                            </Button>

                                        </div>
                                    </section>
                                    <Divider />
                                    <TabSection user={userData} />
                                </Box>
                            </Box>

                            {/* R */}
                            {
                                !isMobile &&
                                <Box sx={{
                                    flex: 2,
                                    backgroundColor: 'primary.second',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'start',
                                    m: 0,
                                    p: '1.8rem 2rem',
                                }}>
                                    <div style={{ position: 'sticky', top: '2.2rem' }}>
                                        <VideoPagePreview
                                            userData={userData}
                                            videoSrc={videoSrc}
                                        />
                                    </div>
                                </Box>
                            }
                        </Box>
                        {isMobile &&
                            <Fab variant="extended"
                                onClick={() => navigate(`/${userData?.username}?preview=true`)}
                                sx={{
                                    position: 'fixed',
                                    bottom: '24px',
                                    right: '16px',
                                    zIndex: 1000,
                                    background: '#000',
                                    color: 'text.regular',
                                    fontWeight: 550
                                    ,
                                }}
                            >
                                <VisibilityIcon sx={{ mr: 1 }} />
                                Preview
                            </Fab>
                        }
                    </Box>
                </MediaProvider>
            </LinksProvider>
        </>
    )
}