import React, { useContext, useState } from 'react'
import { Paper, Typography, useMediaQuery } from '@mui/material'

import { updateFontStyle } from "../../../service/ThemeDataService"
import { VibioThemeContext } from '../../../context/VibioThemeProvider'

const fontStyleMap = {
    REGULAR: 0,
    MONO: 1,
    PLAYFUL: 2,
    ELEGANT: 3,
    BOLD: 4,
}

export default function FontDesign({ themeId, fontStyle }) {
    const isMobile = useMediaQuery('(max-width:1050px)')
    const [selectedFont, setSelectedFont] = useState(fontStyleMap[fontStyle])
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false)
    const { theme, updateTheme } = useContext(VibioThemeContext)

    const getFontFromCode = (code) => {
        switch (code) {
            case 0:
                return 'REGULAR'
            case 1:
                return 'MONO'
            case 2:
                return 'PLAYFUL'
            case 3:
                return 'ELEGANT'
            case 4:
                return 'BOLD'
            default:
                return 'REGULAR'
        }
    }

    const handleFontStyleChange = (newFontStyle) => {
        newFontStyle = Number(newFontStyle)
        setSelectedFont(newFontStyle)
        updateFontStyle(themeId, newFontStyle)
        const updatedTheme = { ...theme, fontStyle: getFontFromCode(newFontStyle) }
        updateTheme(updatedTheme)

        setShowUpdateSuccess(true)
        setTimeout(async () => {
            setShowUpdateSuccess(false)
        }, 550)
    }

    const styleLabel = {
        margin: '0 .8rem 0 0',
        padding: isMobile ? '.4rem 0' : 0,
    }

    const fontStyles = [
        { value: 0, name: 'Regular', fontFamily: 'Catamaran' },
        { value: 3, name: 'Elegant', fontFamily: 'Times New Roman' },
        { value: 4, name: 'Bold', fontFamily: 'Bevan' },
        { value: 1, name: 'Monospace', fontFamily: 'monospace' },
        { value: 2, name: 'Playful', fontFamily: 'Comic Neue' },
    ]


    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    m: isMobile ? '1rem 0' : 2,
                    borderRadius: '10px',
                }}
            >
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                        fontWeight: 600,
                        fontFamily: "inherit",
                        marginBottom: '1rem',
                    }}
                >
                    Font Design
                    {
                        showUpdateSuccess &&
                        <Typography
                            variant="body1"
                            component="p"
                            sx={{
                                fontWeight: 500,
                                fontFamily: 'inherit',
                                color: 'green',
                                margin: '0 1rem',
                                display: 'inline'
                            }}
                        >
                            Change saved!
                        </Typography>
                    }
                </Typography>
                <section style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'flex-start',
                    margin: 0,
                    padding: '0 0 1rem 0',
                }}>
                    {fontStyles.map((font) => (
                        <label key={font.value} style={styleLabel}>
                            <input
                                type="radio"
                                name="font"
                                value={font.value}
                                checked={selectedFont === font.value}
                                onChange={(e) => handleFontStyleChange(e.target.value)}
                            />
                            <Typography
                                variant="h6"
                                component="h6"
                                sx={{
                                    display: 'inline',
                                    fontWeight: font.value === 2 ? 700 : 500,
                                    fontFamily: font.fontFamily,
                                }}
                            >
                                {font.name}
                            </Typography>
                        </label>
                    ))}
                </section>
            </Paper>
        </>
    )
}
