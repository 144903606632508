import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export default function Analytics() {
    const location = useLocation()

    useEffect(() => {
        const id = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
        const isProduction = process.env.NODE_ENV === 'production'

        if (id && isProduction) {
            ReactGA.initialize(id)
            console.log('a initialized')
        } else {
            console.warn('A-ID not found or not in prod.')
        }
    }, [])

    useEffect(() => {
        try {
            ReactGA.send({ hitType: 'pageview', page: location.pathname })
        } catch (error) {
        }
    }, [location])

    return null
}
