import React, { useContext, useState } from 'react'
import { Paper, Typography, Box, useMediaQuery, Divider, Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { styled } from '@mui/system'
import { ChromePicker } from 'react-color'

import { updateButtonStyle, updateButtonColor } from "../../../service/ThemeDataService"
import { VibioThemeContext } from '../../../context/VibioThemeProvider'


const ButtonDesignOptions = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(1.3),
}))

const buttonStyleMap = {
    FILLED_ROUND: 1,
    OUTLINE_REGULAR: 2,
    OUTLINE_ROUND: 3,
    SHADOW_REGULAR: 4,
    SHADOW_ROUND: 5,
}

export default function ButtonDesign({ themeId, btnColor, btnStyleCode }) {
    const isMobile = useMediaQuery('(max-width:1050px)')
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [buttonColor, setButtonColor] = useState(`#${btnColor}`)
    const [selectedBtnStyleCode, setSelectedBtnStyleCode] = useState(buttonStyleMap[btnStyleCode])
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false)
    const { theme, updateTheme } = useContext(VibioThemeContext)

    const baseStyle = {
        padding: isMobile ? '1rem 4rem' : '1.4rem',
        flex: 0.5,
        margin: 0,
        position: 'relative',
    }

    const buttonVariants = [
        {
            style: { backgroundColor: buttonColor },
            label: 'Filled',
            code: 0
        },
        {
            style: { backgroundColor: buttonColor, borderRadius: '16px' },
            label: 'Filled',
            code: 1
        },
        {
            style: {
                backgroundColor: 'transparent',
                border: `3px solid ${buttonColor}`
            },
            label: 'Outline',
            code: 2
        },
        {
            style: {
                backgroundColor: 'transparent',
                border: `3px solid ${buttonColor}`,
                borderRadius: '16px'
            },
            label: 'Outline',
            code: 3
        },
        {
            style: {
                backgroundColor: buttonColor,
                boxShadow: '3px 3px 2px 3px #000'
            },
            label: 'Shadow',
            code: 4
        },
        {
            style: {
                backgroundColor: buttonColor,
                boxShadow: '3px 3px 2px 3px #000',
                borderRadius: '16px'
            },
            label: 'Shadow',
            code: 5
        },
    ]

    const getButtonFromCode = (code) => {
        switch (code) {
            case 0:
                return 'FILLED_REGULAR'
            case 1:
                return 'FILLED_ROUND'
            case 2:
                return 'OUTLINE_REGULAR'
            case 3:
                return 'OUTLINE_ROUND'
            case 4:
                return 'SHADOW_REGULAR'
            default:
                return 'SHADOW_ROUND'
        }
    }

    const handleButtonStyleChange = (btnStyleCode) => {
        setSelectedBtnStyleCode(btnStyleCode)
        updateButtonStyle(themeId, btnStyleCode)
        const updatedTheme = { ...theme, buttonStyle: getButtonFromCode(btnStyleCode) }
        updateTheme(updatedTheme)

        setShowUpdateSuccess(true)
        setTimeout(async () => {
            setShowUpdateSuccess(false)
        }, 550)
    }

    const handleButtonColorChange = (newBtnColor) => {
        setButtonColor(newBtnColor)
        const formatedColor = newBtnColor.slice(1)
        updateButtonColor(themeId, formatedColor)
        const updatedTheme = { ...theme, buttonColor: newBtnColor.slice(1) }
        updateTheme(updatedTheme)

        setShowUpdateSuccess(true)
        setTimeout(async () => {
            setShowUpdateSuccess(false)
        }, 550)
    }

    return (
        <Paper
            elevation={3}
            sx={{
                p: 4,
                m: isMobile ? '.8rem 0' : 2,
                borderRadius: '10px',
            }}
        >
            <Typography
                variant="h6"
                component="h6"
                sx={{
                    fontWeight: 600,
                    fontFamily: 'inherit',
                    marginBottom: 1,
                }}
            >
                Button Design
                {
                    showUpdateSuccess &&
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            color: 'green',
                            margin: '0 1rem',
                            display: 'inline'
                        }}
                    >
                        Change saved!
                    </Typography>
                }
            </Typography>
            <Box>
                <section style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignContent: 'center',
                }}>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            margin: '1rem 1rem 1rem 0rem',
                        }}
                    >
                        Button Color
                    </Typography>
                    <Button
                        variant='outlined'
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            padding: 0,
                            height: '32px',
                            backgroundColor: 'transparent',
                            color: '#000',
                            border: '.8px solid  #aaa',
                            borderRadius: '16px',
                            '&:hover': {
                                border: '.8px solid  #aaa',
                            }
                        }}
                    >
                        <div style={{
                            padding: '10px',
                            background: buttonColor,
                            borderRadius: '10px',
                        }}>
                        </div>
                    </Button>
                </section>

                {/* COLOR PICKER */}
                {showColorPicker &&
                    <div style={{
                        position: 'absolute',
                        zIndex: 1000,
                    }}>
                        <ChromePicker
                            color={buttonColor}
                            onChange={(color) => handleButtonColorChange(color.hex)}
                        />
                    </div>
                }
            </Box>

            <Divider />

            <ButtonDesignOptions>
                {buttonVariants.map((variant, index) => (
                    <React.Fragment key={index}>
                        {index % 2 === 0 &&
                            <>
                                <p>{variant.label}</p>
                                <br />
                            </>
                        }
                        <Box
                            onClick={() => handleButtonStyleChange(variant.code)}
                            sx={{
                                ...baseStyle,
                                ...variant.style,
                                height: '.4rem',
                                cursor: 'pointer',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-12px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: selectedBtnStyleCode === variant.code ? '31%' : '0',
                                    height: '3px',
                                    backgroundColor: '#000',
                                    transition: 'width 0.3s ease',
                                    borderRadius: '20px',
                                },
                            }}
                        />
                    </React.Fragment>
                ))}
            </ButtonDesignOptions>
        </Paper>
    )
}