import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import LinksTab from './links/LinksTab'
import SettingsTab from './settings/SettingsTab'
import { useMediaQuery } from '@mui/material'
import DesignTab from './design/DesignTab'


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function TabSection({ user: userdata }) {
    const [value, setValue] = React.useState(0)
    const isMobile = useMediaQuery('(max-width:1050px)')

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <>
            <Box sx={{
                display: 'block',
                borderBottom: 1,
                borderColor: 'divider',
                width: isMobile ? 'available' : '42vw',
            }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant='fullWidth'
                    aria-label="vibio tabs section"
                    sx={{
                    }}
                >
                    <Tab label="Links" {...a11yProps(0)} />
                    <Tab label="Customize" {...a11yProps(1)} />
                    <Tab label="Settings" {...a11yProps(2)} />
                    {/* <Tab label="Statistics" {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <LinksTab user={userdata} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <DesignTab user={userdata} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <SettingsTab user={userdata} />
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={2}>
                Coming soon
            </CustomTabPanel> */}
        </>
    )
}
