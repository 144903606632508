import { useState, useEffect } from "react"
import { Backdrop, Button, CircularProgress, Divider, Paper, Typography, useMediaQuery } from "@mui/material"
import Swal from "sweetalert2"

import { useAuth } from "../../../context/SecurityContextProvider"
import { initCheckoutSession } from "../../../service/StripeDataService"

const features = [{
    0: ['Unlimited links included ✅', 'Upload videos (up to 50 MB) ✅', 'Not customizable ❌'],
    1: ['Everything in Light tier ✅', 'Customization enabled ✅', 'Upload videos (up to 200 MB) ✅'],
}]

export default function SubscriptionSettings() {
    const { userData } = useAuth()
    const isMobile = useMediaQuery('(max-width:1050px)')
    const [price, setPrice] = useState(0)
    const [tierName, setTierName] = useState("")
    const [showLoading, setShowLoading] = useState(false)


    useEffect(() => {
        switch (userData.tier) {
            case 'LIGHT':
                setPrice(0)
                setTierName("Light")
                break
            case 'STAR_LIGHT':
                setPrice(2.99)
                setTierName("Starlight")
                break
            default:
                setPrice(0)
                setTierName("Light")
                break
        }
    }, [userData.tier])


    const handleCheckout = async () => {
        setShowLoading(true)
        const userId = userData?.userId
        const sessionOK = await initCheckoutSession(userId)
        if (!sessionOK) {
            setShowLoading(false)
            Swal.fire({
                title: "Can't Proceed to Checkout",
                text: "Something went wrong. Please try again later.",
                icon: "error"
            })
        }
    }

    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    m: isMobile ? '1rem 0 0 0' : 2,
                    borderRadius: '10px',
                }}>
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                        fontWeight: 600,
                        fontFamily: "inherit",
                    }}
                >
                    Subscription Details
                </Typography>

                <section>
                    <ul>
                        <li>
                            <Typography
                                wrap
                                variant="body2"
                                component="p"
                                sx={{
                                    fontWeight: 450,
                                    fontFamily: "inherit",
                                    p: '.2rem 0',
                                }}
                            >
                                <strong>Tier: </strong> {tierName}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                wrap
                                variant="body2"
                                component="p"
                                sx={{
                                    fontWeight: 450,
                                    fontFamily: "inherit",
                                    p: '.2rem 0',
                                }}
                            >
                                <strong>Account email:</strong> {userData.email}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                wrap
                                variant="body2"
                                component="p"
                                sx={{
                                    fontWeight: 450,
                                    fontFamily: "inherit",
                                    p: '.2rem 0',
                                }}
                            >
                                <strong>Price: </strong>
                                <span style={{ fontFamily: "Arial", fontSize: '.8rem', fontWeight: 550 }}>
                                    {price === 0 ? 'Free' : `\$${price}/month`}
                                </span>
                            </Typography>
                        </li>
                        {features.map(f => {
                            const tier = price === 0 ? 0 : 1
                            return f[tier].map(perk => (
                                <li key={perk}>
                                    <Typography
                                        wrap
                                        variant="body2"
                                        component="p"
                                        sx={{
                                            fontWeight: 450,
                                            fontFamily: "inherit",
                                            p: '.2rem 0',
                                        }}
                                    >
                                        {perk}
                                    </Typography>
                                </li>
                            ))
                        })}
                    </ul>
                </section>

                <Divider />

                {
                    tierName === 'Light' &&
                    <>
                        <Button
                            onClick={handleCheckout}
                            variant='contained'
                            type='button'
                            sx={{
                                fontSize: '.8rem',
                                textTransform: 'none',
                                m: '1rem .4rem 0 .4rem',
                                p: '.5rem 1rem',
                                fontFamily: "inherit",
                                width: '100%',
                                background: 'primary.main',
                                fontWeight: 650,
                                '&:hover': {
                                    background: 'links.main'
                                }
                            }}
                        >
                            Upgrade to Starlight — 7 day Free Trial
                        </Button>
                        <center>
                            <pre style={{ fontSize: '80%' }}>$2.99/month after</pre>
                        </center>
                    </>
                }

                <Button
                    variant='contained'
                    type='button'
                    href="https://billing.stripe.com/p/login/bIY7sE4HJdpR5yw5kk"
                    sx={{
                        fontSize: '.8rem',
                        textTransform: 'none',
                        m: '1rem .4rem',
                        p: '.5rem 1rem',
                        fontFamily: "inherit",
                        width: '100%',
                        background: '#000'
                    }}
                >
                    Manage My Account
                </Button>

            </Paper>
            <Backdrop
                sx={(theme) => ({ color: '#ff1493', zIndex: theme.zIndex.drawer + 1 })}
                open={showLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
