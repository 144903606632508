import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import './App.css'

import { SecurityContextProvider } from './context/SecurityContextProvider'

import VideoPage from './components/views/videopage/VideoPage'
import LandingPageView from './components/views/landing/LangingPageView'
import ProfileSetUpView from './components/views/login/ProfileSetUpView'
import LogInView from './components/views/login/LogInView'
import StudioView from './components/views/studio/StudioView'
import ProtectedRoutes from './components/nav/ProtectedRoutes'
import TermsOfService from './components/views/terms-privacy/TermsOfService'
import PrivacyPolicy from './components/views/terms-privacy/PrivacyPolicy'
import { VibioThemeProvider } from './context/VibioThemeProvider'
import Analytics from './util/Analytics'
import PaymentFail from './components/views/payment/PaymentFail'



const queryClient = new QueryClient()

const theme = createTheme({
    palette: {
        primary: {
            main: '#FF1493',
            second: '#e9ecef',
            third: '#f2f2f2',
            bg: '#fcfcfc',
            bg2: '#f4f4f4',
            black: "#000",
        },
        links: {
            main: '#2B061E',
            icon: "#2B061E",
            btn: "#FF1493",
            text: "#fdfdfd",
            bg: "#03045e",
        },
        text: {
            regular: "#f6f6f6",
            second: '#211A1E',
            gray: '#8a817c',
            darkGray: '#343a40',
        }
    },
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <SecurityContextProvider>
                    <VibioThemeProvider>
                        <BrowserRouter>
                            <Analytics />
                            <Routes>
                                <Route path="/" element={<LandingPageView />} />
                                <Route path="/login" element={<LogInView />} />
                                <Route path="/:username" element={<VideoPage />} />
                                <Route path="/terms" element={<TermsOfService />} />
                                <Route path="/privacy" element={<PrivacyPolicy />} />

                                <Route element={<ProtectedRoutes />}>
                                    <Route path="/studio" element={<StudioView />} />
                                    <Route path="/welcome" element={<ProfileSetUpView />} />
                                    <Route path="/payment-fail" element={<PaymentFail />} />
                                </Route>

                            </Routes>
                        </BrowserRouter>
                    </VibioThemeProvider>
                </SecurityContextProvider>
            </QueryClientProvider>
        </ThemeProvider>
    )
}

export default App
