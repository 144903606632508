import { useMediaQuery } from '@mui/material'
import { useState, useEffect } from 'react'

export default function ProfileCardCarousel() {
    const [position, setPosition] = useState(0)
    const isMobile = useMediaQuery('(max-width:950px)')

    const cards = [
        { name: "jessica-the-foodie", theme: "pink", img: "/p1.png" },
        { name: "Ben-Morris", theme: "blue", img: "/p2.png" },
        { name: "LunarAura", theme: "purple", img: "/p3.png" },
    ]

    useEffect(() => {
        const timer = setInterval(() => {
            setPosition((prev) => (prev + 1) % 3)
        }, 2000)
        return () => clearInterval(timer)
    }, [])

    const getCardStyle = (index) => {
        const normalizedPosition = ((index - position + 3) % 3)

        const styles = {
            0: {
                transform: "translateX(0) scale(1)",
                zIndex: 30,
            },
            1: {
                transform: "translateX(-30px) scale(0.95)",
                zIndex: 20,
            },
            2: {
                transform: "translateX(-60px) scale(0.9)",
                zIndex: 10,
            },
        }

        return styles[normalizedPosition]
    }

    const containerStyle = {
        margin: isMobile ? '3rem 0 8rem 0' : '1.2rem .4rem',
        position: "relative",
        width: isMobile ? "12rem" : "24rem",
        height: isMobile ? "16rem" : "32rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f3f4f6",
        borderRadius: "1rem",
    }

    const carouselStyle = {
        position: "relative",
        width: isMobile ? "12rem" : "24rem",
        height: isMobile ? "16rem" : "32rem",
    }

    const cardStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: isMobile ? "16rem" : "24rem",
        height: isMobile ? "24rem" : "32rem",
        borderRadius: "0.5rem",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.6)",
        overflow: "hidden",
        transition: "all 0.6s ease-in-out",
        transformOrigin: "center center",
    }

    return (
        <div style={containerStyle}>
            <div style={carouselStyle}>
                {cards.map((card, index) => (
                    <div
                        key={card.name}
                        style={{ ...cardStyle, ...getCardStyle(index) }}
                    >
                        <img
                            src={`${card.img}`}
                            alt={card.name}
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "fill",
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
