import BenefitsSectionNew from "../../landing/BenefitsSectionNew"
import FAQSection from "../../landing/FAQSection"
import FeaturesSection from "../../landing/FeaturesSection"
import Footer from "../../landing/Footer"
import GetStartedReminderSection from "../../landing/GetStartedReminderSection"
import HeroSection from "../../landing/HeroSection"
import SocialProofSection from "../../landing/SocialProofSection"
import Navbar from "../../nav/Navbar"


export default function LandingPageView() {
    return (
        <>
            <Navbar />
            <HeroSection />
            {/* <SocialProofSection /> */}
            <BenefitsSectionNew />
            <FeaturesSection />
            <GetStartedReminderSection />
            <FAQSection />
            <Footer />
        </>
    )
}
