import { useEffect, useState } from "react"
import { Box, Paper, Typography } from "@mui/material"
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

export default function PaymentFail() {
    const [seconds, setSeconds] = useState(5)

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => {
                setSeconds(seconds - 1)
            }, 1000)

        } else {
            window.location.href = "/studio"
        }

    }, [seconds])

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100dvh",
                fontFamily: "Catamaran",
                backgroundColor: "primary.main",
            }}
        >
            <Paper
                elevation={4}
                sx={{
                    m: 2,
                    p: '2rem 3rem',
                    textAlign: "center",
                }}
            >
                <CancelRoundedIcon sx={{
                    transform: 'scale(2.4)',
                    color: 'red',
                }} />

                <Typography
                    variant="h5"
                    component="h3"
                    sx={{
                        fontSize: "2rem",
                        fontWeight: 800,
                        fontFamily: "inherit",
                        m: 1,
                        p: .5,
                    }}
                >
                    Failed!
                </Typography>

                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        fontWeight: 500,
                        fontFamily: "inherit",
                    }}
                >
                    Something went wrong while processing your order. Please try again later.
                    <br />
                    We're redirecting you back to your Vibio profile in <b>{seconds}</b> seconds.
                </Typography>

            </Paper>
        </Box>
    )

}