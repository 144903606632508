import React, { useContext, useCallback } from "react"
import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import UploadIcon from '@mui/icons-material/Upload'
import InstagramIcon from '@mui/icons-material/Instagram'
import {
    DndContext, closestCorners, PointerSensor, TouchSensor, useSensor, useSensors
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { LinksContext } from "../../../context/LinkProvider"
import { SocialLinkList } from "../../links/SocialLinkList"
import { LinkButtonStudio } from "../../links/LinkButtonStudio"
import { SocialLinkModal } from "./modals/sociallink/SocialLinkModal"
import { AddLinkButonModal } from "./modals/linkbutton/AddLinkButtonModal"
import { MediaContext } from "../../../context/MediaProvider"

export default function LinksTab({ user }) {
    const {
        linkButtons,
        socialLinks,
        openLinkModal,
        setOpenLinkModal,
        openIconModal,
        setOpenIconModal,
        handleAddLink,
        handleReorderLinks,
        handleUpdateLink,
        handleDeleteLink,
        handleSocialIconChange,
    } = useContext(LinksContext)

    const { uploadVideo } = useContext(MediaContext)
    const isMobile = useMediaQuery('(max-width:1050px)')

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 10,
                distance: 5
            }
        })
    )

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event
        if (active && over && active.id !== over.id) {
            const oldIndex = linkButtons.findIndex(lb => lb.id === active.id)
            const newIndex = linkButtons.findIndex(lb => lb.id === over.id)

            if (oldIndex !== -1 && newIndex !== -1) {
                const newLinkButtons = arrayMove([...linkButtons], oldIndex, newIndex)
                handleReorderLinks(newLinkButtons)
            }
        }
    }, [linkButtons, handleReorderLinks])

    const actionBtnStyle = {
        m: 0,
        textTransform: "none",
        flex: 1,
        fontWeight: 600,
    }

    return (
        <>
            <Box sx={{
                display: "flex",
                flexDirection: isMobile ? 'column' : 'row',
                gap: 2,
                justifyContent: "space-evenly",
                marginBottom: '2rem',
            }}>
                <Button
                    variant="contained"
                    sx={actionBtnStyle}
                    onClick={() => setOpenLinkModal(true)}
                    startIcon={<PlaylistAddIcon />}
                >
                    Add Link
                </Button>
                <Button
                    variant="contained"
                    sx={actionBtnStyle}
                    onClick={() => setOpenIconModal(true)}
                    startIcon={<InstagramIcon />}
                >
                    Add Social Link
                </Button>
                <Button
                    variant="contained"
                    sx={actionBtnStyle}
                    onClick={uploadVideo}
                    startIcon={<UploadIcon />}
                >
                    Upload Video
                </Button>
            </Box>

            <SocialLinkList socials={socialLinks} />

            <Box sx={{ marginTop: 0 }}>
                {linkButtons.length === 0 && (
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontWeight: 400,
                            textAlign: "center",
                            m: "3rem 0 0 0"
                        }}>
                        Your links will appear here.
                    </Typography>
                )}

                <DndContext
                    onDragEnd={handleDragEnd}
                    collisionDetection={closestCorners}
                    sensors={sensors}
                >
                    <SortableContext items={linkButtons} strategy={verticalListSortingStrategy}>
                        {linkButtons.map(lb => (
                            <LinkButtonStudio
                                key={lb.uuid}
                                {...lb}
                                onUpdate={handleUpdateLink}
                                onDelete={handleDeleteLink}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </Box>

            <AddLinkButonModal
                open={openLinkModal}
                onClose={() => setOpenLinkModal(false)}
                onAddLink={handleAddLink}
            />
            <SocialLinkModal
                open={openIconModal}
                onAddIcon={handleSocialIconChange}
                onClose={() => setOpenIconModal(false)}
                pageId={user.videoPages[0].pageId}
            />
        </>
    )
}
