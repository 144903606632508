import * as React from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'


function GetStartedVibioForm() {
    const [vibioUsername, setVibioUsername] = React.useState('')
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:950px)')


    const handleVibioUsernameChange = (event) => {
        setVibioUsername(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // You can add your form submission logic here
        console.log('Submitted username:', vibioUsername)
        // Clear the email field after submission
        setVibioUsername('')
        navigate("/login")
    }

    return (

        <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: isMobile ? 'center' : 'start',
                fontFamily: 'catamaran',
            }}>
            <section style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <TextField
                    label="vibio.co/"
                    variant="outlined"
                    size="medium"
                    type="text"
                    placeholder='your name'
                    value={vibioUsername}
                    onChange={handleVibioUsernameChange}
                    sx={{
                        mr: 1.2,
                        border: 'none',
                        backgroundColor: '#fdfdfd',
                        gap: 4,
                        fontFamily: 'catamaran',
                        borderRadius: '10px',
                    }}
                />

                <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={{
                        p: '.8rem .6rem',
                        fontFamily: 'inherit',
                        backgroundColor: 'primary.main',
                        color: 'text.regular',
                        textTransform: 'none',
                        boxShadow: "2px 3px 0px #000",
                    }}
                >
                    <span style={{ fontSize: '1rem', fontWeight: 800 }}>Sign me Up</span>
                </Button>
            </section>
            <section style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <p style={{ fontWeight: 500, color: "#495057", textAlign: isMobile ? 'center' : 'start' }}>
                    Get set up in 30 seconds for Free. No credit card required.
                </p>
            </section>
        </Box>
    )
}

export default GetStartedVibioForm