import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/SecurityContextProvider'
import DropdownButton from '../buttons/DropdownButton'
import MenuIcon from '@mui/icons-material/Menu'
import { useMediaQuery } from '@mui/material'


function Navbar() {
    const { isAuthenticated, userData } = useAuth()
    const isMobile = useMediaQuery('(max-width:950px)')

    const handleLogin = () => {
        console.log("Logging in...")
    }

    return (
        <AppBar position="static" sx={{
            backgroundColor: 'primary.main',
            color: 'primary.bg',
            p: 1,
        }}>
            <Container>
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontFamily: 'Catamaran',
                }}>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            fontSize: '2.2rem',
                            fontWeight: 800,
                            fontFamily: 'inherit',
                        }}
                    >
                        Vibio
                    </Typography>

                    {
                        !isAuthenticated &&
                        <Button onClick={handleLogin}
                            component={Link}
                            to='/login'
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                backgroundColor: 'primary.bg',
                                fontWeight: 600,
                                fontSize: "1rem",
                                fontFamily: 'inherit',
                                textTransform: 'none',
                                color: '#000',
                                boxShadow: "0px 3px 5px #000",
                            }}>
                            Log In
                        </Button>
                    }

                    {
                        isAuthenticated ?
                            <DropdownButton username={isMobile ? <MenuIcon /> : userData?.username} />
                            :
                            <Button onClick={handleLogin}
                                component={Link}
                                to='/login'
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                    color: 'primary.bg',
                                    fontSize: "1rem",
                                    fontFamily: 'inherit',
                                    textTransform: 'none',
                                }}>
                                <span style={{
                                    alignSelf: 'center',
                                    padding: '.4rem 1rem',
                                    borderRadius: '4px',
                                    background: '#fff',
                                    boxShadow: "0px 3px 5px #000",
                                    color: '#000',
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                }}>
                                    Log In
                                </span>
                            </Button>
                    }
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navbar
