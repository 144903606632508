import { Button, CardMedia, Typography, useMediaQuery } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { Link } from "react-router-dom"
import ProfileCardCarousel from "./mockups/ProfileCardCarousel"

export default function BenefitsSectionNew() {
    const isMobile = useMediaQuery('(max-width:950px)')

    const hFontSize = '2rem'
    const pFontSize = '1.2rem'

    return (
        <>
            <Box sx={{
                margin: isMobile ? '.8rem' : '4rem 14dvw',
                padding: isMobile ? '2rem 0' : '2dvh 6dvw',
                borderRadius: '20px',
                backgroundColor: 'primary.bg2',
                fontFamily: 'catamaran',
            }}>

                <Box sx={{
                    m: 0,
                    p: isMobile ? '1rem 1.2rem' : '0 1rem',
                    gap: 0,
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'center',
                }}>
                    <article style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        maring: 0,
                        padding: '0 1rem',
                    }}>
                        <Typography variant={isMobile ? 'h5' : 'h5'} component="h2"
                            sx={{
                                fontFamily: 'inherit',
                                fontWeight: 800,
                                fontSize: hFontSize,
                                textDecoration: '3px underline #FF1493',
                                mb: 2,
                            }}>
                            Engaging Bios
                        </Typography>
                        <Typography variant="p" component="body1"
                            sx={{
                                fontFamily: 'inherit',
                                fontWeight: 500,
                                fontSize: pFontSize
                            }}>
                            Create more engaging and creative link-in-bio for you and your
                            business. Express yourself to your audience just the way you like!
                        </Typography>
                        <Button
                            component={Link}
                            to='/login'
                            variant="contained"
                            disableElevation
                            sx={{
                                mt: 4,
                                p: '.8rem .6rem',
                                fontFamily: 'inherit',
                                backgroundColor: 'primary.main',
                                color: 'text.regular',
                                textTransform: 'none',
                                boxShadow: "2px 3px 0px #000",
                            }}
                        >
                            <span style={{ fontSize: '1rem', fontWeight: 800 }}>Create Account</span>
                        </Button>
                    </article>
                    <section style={{
                        flex: 1,
                    }}>
                        <CardMedia
                            muted
                            playsInline
                            component="video"
                            src="/jessica-2.mp4"
                            title="Product showcase 1"
                            autoPlay
                            loop
                            sx={{
                                m: 0,
                                p: 0,
                                height: '66dvh',
                                objectFit: 'contain',
                                flex: 1,
                            }}
                        />
                    </section>
                </Box>

                <Box sx={{
                    m: 0,
                    p: isMobile ? '1rem 1.2rem' : '0 1rem',
                    display: 'flex',
                    flexDirection: isMobile ? 'column-reverse' : 'row',
                    alignItems: 'center',
                }}>
                    <section style={{
                        flex: 1,
                    }}>
                        <ProfileCardCarousel />
                    </section>
                    <article style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        maring: 0,
                        padding: '0 1rem',
                    }}>
                        <Typography variant={isMobile ? 'h5' : 'h5'} component="h2"
                            sx={{
                                fontFamily: 'inherit',
                                fontWeight: 800,
                                fontSize: hFontSize,
                                textDecoration: '3px underline #FF1493',
                                mb: 2,
                            }}>
                            All in One
                        </Typography>
                        <Typography variant="p" component="body1"
                            sx={{
                                fontFamily: 'inherit',
                                fontWeight: 500,
                                fontSize: pFontSize,
                            }}>
                            Link your social media accounts, add any links you have; products, calendars,
                            website, affiliates and more. Your online & offline presence in one place complimented with Vibio!
                        </Typography>
                        <Button
                            component={Link}
                            to='/login'
                            variant="contained"
                            disableElevation
                            sx={{
                                mt: 4,
                                p: '.8rem .6rem',
                                fontFamily: 'inherit',
                                backgroundColor: 'primary.main',
                                color: 'text.regular',
                                textTransform: 'none',
                                boxShadow: "2px 3px 0px #000",
                            }}
                        >
                            <span style={{ fontSize: '1rem', fontWeight: 800 }}>Sign Up</span>
                        </Button>
                    </article>
                </Box>

            </Box>
        </>
    )
}