import { Avatar, Box, CardMedia, List, ListItem, ListItemAvatar, ListItemText, Typography, useMediaQuery } from "@mui/material"
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'

import { PartyPopper, Link, BadgeCheck } from 'lucide-react'



const features = [
    {
        id: 0,
        title: 'Catchy & Engaging',
        desc: "Your video is the highlight of your bio page. It plays in infinite loop. The video will allow you to increase your engagement with your visitors."
    },
    {
        id: 1,
        title: 'Unlimited Links',
        desc: "Add all your links, affiliates, socials, and more. There's simply no limit."
    },
    {
        id: 2,
        title: 'Iconic',
        desc: 'Addition to the links, you can add icons to social media platforms, e-commerce sites, and more!'
    },
    {
        id: 3,
        title: 'Personalized',
        desc: 'Vibio offers extensive customization options for your personal brand or business. Colors, font styles, and button designs.'
    },
    {
        id: 4,
        title: 'Accessible',
        desc: 'Vibio is a web based application. As long as you have internet connection, you can make changes to your profile.'
    },
]

export default function FeaturesSection() {
    const isMobile = useMediaQuery('(max-width:950px)')

    const featureIconMap = {
        0: <PartyPopper />,
        1: <Link />,
        2: <BadgeCheck />,
        3: <ColorLensRoundedIcon />,
        4: <PublicRoundedIcon />
    }

    return (
        <>
            <Box sx={{
                margin: isMobile ? '.8rem' : '10rem 14dvw',
                padding: isMobile ? '2rem 0' : '2dvh 0',
                borderRadius: '20px',
                backgroundColor: 'primary.bg2',
                fontFamily: 'catamaran',
                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'row',
            }}>
                <CardMedia
                    component="img"
                    src="/mockup-still.png"
                    title="Vibio mockup"
                    sx={{
                        m: 0,
                        p: 0,
                        height: '72dvh',
                        objectFit: 'contain',
                        alignSelf: 'center',
                        flex: 2,
                    }}
                />
                <section style={{
                    flex: 1.4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: isMobile ? 'center' : 'start',
                }}>
                    <Typography variant={isMobile ? 'h5' : 'h4'} component="h2"
                        sx={{
                            fontFamily: 'inherit',
                            fontWeight: 600,
                            m: 3,
                        }}>
                        Bio essentials, included!
                    </Typography>
                    <List sx={{ width: '100%', maxWidth: 360, }}>
                        {
                            features.map(f => {
                                return (
                                    <ListItem id={f.id} sx={{ mb: 1, gap: 1, fontFamily: 'Catamaran' }}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 56, height: 56, backgroundColor: 'primary.main', color: 'primary.bg' }}>
                                                {featureIconMap[f.id]}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={f.title}
                                            secondary={f.desc} />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </section>

            </Box>
        </>
    )
}