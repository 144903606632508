import React from 'react'
import { Box, Divider, Typography } from "@mui/material"

// import FacebookLoginButton from "../../buttons/login/FacebookLoginButton"
// import TwitterLoginButton from "../../buttons/login/TwitterLoginButton"
import GoogleLoginButton from '../../buttons/login/GoogleLoginButton'


export default function LogInView() {
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                m: 0,
                p: 0,
                gap: 1,
                height: '100vh',
                fontFamily: 'Catamaran',
                backgroundColor: 'primary.main',
            }}>
                {/* L */}
                <Box sx={{
                    flex: 3,
                    // border: '1px orange solid',
                    color: 'text.regular',
                    m: 1,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            fontWeight: 900,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        Vibio
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        Video link-in-bio tool
                    </Typography>
                </Box>

                {/* R */}
                <Box sx={{
                    flex: 2,
                    // border: '1px cyan solid',
                    backgroundColor: 'primary.bg',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {/* Log In Container */}
                    <Box
                        sx={{
                            // textAlign: 'center'
                            m: 1,
                            p: '4vw',
                            borderRadius: '20px',
                        }}>
                        <Typography
                            variant="h4"
                            component="h4"
                            sx={{
                                fontWeight: 700,
                                fontFamily: 'inherit',
                                textAlign: 'center',
                            }}
                        >
                            Welcome to Vibio
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            sx={{
                                fontWeight: 400,
                                fontFamily: 'inherit',
                                textAlign: 'center',
                            }}
                        >
                            Let's get you started
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 2,
                            p: 1,
                            gap: 2,
                        }}>
                            <GoogleLoginButton />
                            <p>More Sign in options coming soon</p>
                            {/* <FacebookLoginButton />
                            <TwitterLoginButton /> */}
                        </Box>
                        <Divider />
                        <center>
                            <p>
                                by continuing you agree to our
                                <br />
                                <a href='/terms' style={{ color: '#ff1493' }}>Terms</a> & <a href='/privacy' style={{ color: '#ff1493' }}>Privacy</a>
                            </p>
                        </center>

                    </Box>
                </Box>
            </Box>
        </>
    )
}
