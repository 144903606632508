import { createContext, useCallback, useState } from "react"


export const VibioThemeContext = createContext()

export const VibioThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(null)

    const updateTheme = useCallback((newTheme) => {
        setTheme(newTheme)
    }, [])

    return (
        <VibioThemeContext.Provider value={{ theme, updateTheme }}>
            {children}
        </VibioThemeContext.Provider>
    )
}
