import React, { createContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { uploadNewVideo } from "../service/VideoDataService"
import {
    getUserById,
    updateUserProfilePicture,
    removeUserProfilePicture,

} from "../service/UserDataService"


export const MediaContext = createContext()

export const MediaProvider = ({ user, children }) => {
    const [videoSrc, setVideoSrc] = useState(user?.videoPages[0].videoSrc)
    const [profilePicture, setProfilePicture] = useState(user?.profilePicture)
    const [videoUploadLimit, setVideoUploadLimit] = useState(50)


    useEffect(() => {
        const fetchUser = async () => {
            const fetchedUser = await getUserById(user.userId)
            setProfilePicture(fetchedUser.profilePicture)
            if (fetchedUser.tier === 'LIGHT') {
                setVideoUploadLimit(50)
            } else {
                setVideoUploadLimit(200)
            }
        }
        fetchUser()
    }, [user.profilePicture])


    const handleDeleteProfilePic = async () => {
        Swal.fire({
            title: "Remove profile picture?",
            text: "This action cannot be undone!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#495057",
            confirmButtonText: "Remove"
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const isRemoveOK = await removeUserProfilePicture(user.userId)
                    if (isRemoveOK) {
                        Swal.fire({
                            title: "Removed!",
                            text: "Your profile picture has been removed.",
                            icon: "success"
                        })
                        setProfilePicture("")
                    } else if (!isRemoveOK) {
                        Swal.fire({
                            title: "Opps, Something went wrong!",
                            text: "Failed to remove profile picture. Please try again later.",
                            icon: "error"
                        })
                    }
                }
            })

    }

    const handleUpdateProfilePic = async () => {
        const { value: file } = await Swal.fire({
            title: "Select image",
            input: "file",
            inputAttributes: {
                "accept": "image/*",
                "aria-label": "Upload profile picture"
            }
        })
        if (file) {
            // Show loading screen
            Swal.fire({
                title: 'Uploading...',
                html: 'Please wait. Uploading your image.',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading()
                }
            })
            const reader = new FileReader()
            reader.onload = async (e) => {

                const formData = new FormData()
                formData.append("file", file)
                const pictureURL = await updateUserProfilePicture(user.userId, formData)

                Swal.close()

                if (pictureURL) {
                    Swal.fire({
                        title: "Image upload successful!",
                        html: `<img 
                            src="${e.target.result}"
                            alt="Your browser does not support the img tag"
                            width="240"
                            height="240"
                        />`,
                        icon: "success",
                    })
                    setProfilePicture(e.target.result)
                    user.profilePicture = pictureURL
                } else {
                    Swal.fire({
                        title: "Upload failed",
                        text: "There was a problem with the upload.",
                        icon: "error",
                    })
                }

            }

            reader.readAsDataURL(file)

        }
    }

    const uploadVideo = async () => {
        const { value: file } = await Swal.fire({
            title: "Select video",
            text: `(${videoUploadLimit}mb maximum)`,
            input: "file",
            inputAttributes: {
                accept: "video/*",
                "aria-label": "Upload your video",
            },
        })

        if (file) {
            // Show loading screen
            Swal.fire({
                title: 'Uploading...',
                html: 'Please wait. Uploading your video.',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading()
                }
            })

            const reader = new FileReader()
            reader.onload = async (e) => {
                const formData = new FormData()
                formData.append("file", file)

                let videoSize = file.size / (1024 * 1024)
                if (videoSize > videoUploadLimit) {
                    Swal.fire({
                        title: 'Video exceeds the upload limit',
                        html: `Please make sure your max video size is ${videoUploadLimit} MB.`,
                        allowOutsideClick: true,
                        showConfirmButton: true,
                        icon: "warning",
                    })
                    return
                }
                const videoPageId = user.videoPages[0].pageId
                const [isUploadOK, src] = await uploadNewVideo(videoPageId, formData)
                console.log(src)
                setVideoSrc(src)

                Swal.close()

                if (isUploadOK) {
                    Swal.fire({
                        title: "Upload successful!",
                        html: `<video controls width="50%">
                                <source src="${e.target.result}" type="${file.type}">
                                Your browser does not support the video tag.
                              </video>`,
                        showConfirmButton: true,
                        icon: "success",
                    })
                } else {
                    Swal.fire({
                        title: "Upload failed",
                        text: "There was a problem with the upload.",
                        icon: "error",
                    })
                }
            }
            reader.readAsDataURL(file)
        }
    }

    return (
        <MediaContext.Provider value={{
            handleDeleteProfilePic,
            handleUpdateProfilePic,
            uploadNewVideo,
            profilePicture,
            uploadVideo,
            videoSrc
        }}>
            {children}
        </MediaContext.Provider>
    )
}