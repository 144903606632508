import { Box, CardMedia, Typography } from "@mui/material"
import React from "react"
import GetStartedVibioForm from "./GetStartedVibioForm"
import { useMediaQuery } from '@mui/material'


export default function HeroSection() {
    const isMobile = useMediaQuery('(max-width:950px)')

    return (
        <>
            <Box sx={{
                backgroundColor: 'primary.bg',
                color: 'primary.main',
                m: isMobile ? '1rem 0' : '2rem 8dvw',
                p: isMobile ? '1rem 0' : '3rem 6dvw',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexWrap: 'wrap',
                minHeight: 660,
            }}>

                {/* text section */}
                <Box sx={{
                    flex: 4,
                    textAlign: 'left',
                    m: 0,
                    p: '0 1.2rem',
                    fontWeight: 600,
                    fontFamily: 'Catamaran',
                }}>

                    {/* banner */}
                    <Box sx={{
                        m: '0 0 2rem 0',
                        p: '0 .2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: isMobile ? '' : 'center',
                        height: isMobile ? '60vh' : '74vh',
                    }}>
                        <Typography
                            variant={isMobile ? 'h3' : 'h2'}
                            component="h2" sx={{
                                fontSize: isMobile ? '3.1rem' : '4.5rem',
                                fontFamily: 'inherit',
                                fontWeight: 900,
                                mb: 2
                            }}>
                            Say goodbye <br /> to boring bio tools
                            {/* with Vibio */}
                        </Typography>

                        <Typography
                            variant="h6"
                            component="h4"
                            sx={{
                                fontFamily: 'inherit',
                                fontWeight: 600,
                                mb: 4,
                                maxWidth: '98%',
                                color: 'text.darkGray'
                            }}>
                            Turn your bio into a video. Share your work, tell your story, and showcase your vibe.
                            All your links and socials in one place.
                        </Typography>

                        <GetStartedVibioForm />
                    </Box>

                </Box>

                {/* media section */}
                <Box sx={{
                    flex: 3,
                    // m: isMobile ? '13.6vh 0 0 0' : 0,
                    m: isMobile ? '8dvh 0 0 0' : '-6vh 0 0 0',
                    p: 0,
                }}>
                    <CardMedia
                        muted
                        playsInline
                        component="video"
                        src="/jessica.mp4"
                        title="Vibio in action mockup"
                        autoPlay
                        loop
                        sx={{
                            m: 0,
                            p: 0,
                            height: '92vh',
                            objectFit: 'contain'
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}
