import React, { useState, useRef, useContext } from 'react'
import { Box, Chip, CircularProgress, Typography } from '@mui/material'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

import { LinksContext } from '../../../../context/LinkProvider'
import { MediaContext } from '../../../../context/MediaProvider'
import ProfilePreviewCard from './ProfilePreviewCard'


export function VideoPagePreview({ userData }) {
    const videoRef = useRef(null)
    const [onMute, setOnMute] = useState(true)
    const { linkButtons, socialLinks } = useContext(LinksContext)
    const { videoSrc } = useContext(MediaContext)

    const toggleMute = () => {
        console.log('toggle mute')
        setOnMute(!onMute)
    }

    return (
        <>
            <Box
                sx={{
                    m: 0,
                    p: 0,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-evenly',
                    gap: 1.2,
                }}
            >
                <Chip
                    label='Video'
                    onClick={toggleMute}
                    icon={onMute ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    sx={{
                        color: 'primary.bg',
                        backgroundColor: 'primary.main',
                        '& .MuiChip-icon': {
                            color: 'primary.bg',
                        },
                        ":hover": {
                            backgroundColor: '#FF0271',
                        },
                    }}
                />
                <div style={{
                    margin: 0,
                    padding: 0,
                    height: '82vh',
                    width: '18.4vw',
                    overflow: 'hidden',
                    border: '11px solid #000',
                    borderRadius: '32px',
                }}>
                    {videoSrc ? (
                        <>
                            <video
                                style={{
                                    height: '82vh',
                                    objectFit: 'cover'
                                }}
                                src={videoSrc}
                                autoPlay
                                playsInline
                                loop
                                ref={videoRef}
                                muted={onMute}
                            />
                            <ProfilePreviewCard
                                socials={socialLinks}
                                linkButtons={linkButtons}
                                userData={userData}
                            />
                        </>
                    ) : (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}>
                            {videoRef ?
                                <Typography
                                    variant="body"
                                    component="p"
                                    sx={{
                                        fontFamily: 'inherit',
                                        fontWeight: 500,
                                        margin: '1rem 0',
                                        textAlign: 'center',
                                    }}
                                >
                                    Upload a video to view it in preview.
                                </Typography>
                                :
                                <>
                                    <Typography
                                        variant="body"
                                        component="p"
                                        sx={{
                                            fontFamily: 'inherit',
                                            fontWeight: 500,
                                            margin: '1rem 0',
                                        }}
                                    >
                                        Loading preview
                                    </Typography>
                                    <CircularProgress />
                                </>
                            }
                        </div>
                    )}

                </div>
            </Box>

        </>
    )
}

