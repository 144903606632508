import React, { useContext, useState } from 'react'
import { Paper, Typography, useMediaQuery, Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ChromePicker } from 'react-color'

import { updateSocialIconColor } from "../../../service/ThemeDataService"
import { VibioThemeContext } from '../../../context/VibioThemeProvider'


export default function IconDesign({ themeId, iconColor }) {
    const isMobile = useMediaQuery('(max-width:1050px)')
    const [socialIconColor, setSocialIconColor] = useState(`#${iconColor}`)
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false)
    const { theme, updateTheme } = useContext(VibioThemeContext)

    const handleSocialIconColorChange = (newIconColor) => {
        setSocialIconColor(newIconColor)
        updateSocialIconColor(themeId, newIconColor.slice(1))
        const updatedTheme = { ...theme, iconColor: newIconColor.slice(1) }
        updateTheme(updatedTheme)

        setShowUpdateSuccess(true)
        setTimeout(async () => {
            setShowUpdateSuccess(false)
        }, 550)
    }

    const handleResetSocialIconColor = () => {
        setSocialIconColor("#fff")
        updateSocialIconColor(themeId, "x")
        const updatedTheme = { ...theme, iconColor: "x" }
        updateTheme(updatedTheme)

        setShowUpdateSuccess(true)
        setTimeout(async () => {
            setShowUpdateSuccess(false)
        }, 550)
    }

    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    m: isMobile ? 0 : 2,
                    marginTop: '1rem',
                    borderRadius: '10px',
                }}>
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                        fontWeight: 600,
                        fontFamily: "inherit",
                    }}
                >
                    Social Icon Design
                    {
                        showUpdateSuccess &&
                        <Typography
                            variant="body1"
                            component="p"
                            sx={{
                                fontWeight: 500,
                                fontFamily: 'inherit',
                                color: 'green',
                                margin: '0 1rem',
                                display: 'inline'
                            }}
                        >
                            Change saved!
                        </Typography>
                    }
                </Typography>

                <section style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignContent: 'center',
                }}>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            margin: '1rem 1rem 1rem 0rem',
                        }}
                    >
                        Icon Color
                    </Typography>
                    <Button
                        variant='outlined'
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            padding: 0,
                            height: '32px',
                            backgroundColor: 'transparent',
                            color: '#000',
                            border: '.8px solid  #aaa',
                            borderRadius: '16px',
                            '&:hover': {
                                border: '.8px solid  #aaa',
                            }
                        }}
                    >
                        <div style={{
                            padding: '10px',
                            background: socialIconColor,
                            border: '1px solid #000',
                            borderRadius: '10px',
                        }}>
                        </div>
                    </Button>
                </section>
                <section style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignContent: 'center',
                }}>
                    <Button
                        variant='outlined'
                        onClick={() => handleResetSocialIconColor("")}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            backgroundColor: '#fcfcfc',
                            color: '#0a0a0a',
                            borderRadius: '14px',
                            textTransform: 'none',
                            fontSize: '.68rem',
                            fontFamily: 'inherit',
                            border: '1px solid #aaa',
                            '&:hover': {
                                backgroundColor: '#f0f0f0',
                                border: '1px solid #aaa',
                            }
                        }}
                    >
                        Reset Icon Colors
                    </Button>
                </section>


                {/* COLOR PICKER */}
                {showColorPicker &&
                    <div style={{
                        position: 'absolute',
                        zIndex: 1000,
                    }}>
                        <ChromePicker
                            color={socialIconColor}
                            onChange={(color) => handleSocialIconColorChange(color.hex)}
                        />
                    </div>
                }
            </Paper>
        </>
    )

}
